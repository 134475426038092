body{
  position: relative;
  margin: 0;
  padding: 0;
  /* background: linear-gradient(180deg, #0D5373 0%, #061D27 100%); */
  background: #fdf7ec;
  background-attachment: fixed;
  min-height: 100vh;
  font-family: 'Raleway', sans-serif;
}
input,textarea,select,button{
  font-family: 'Raleway', sans-serif;
}
.contenedor{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 7%;
}
a{
  color: #5B5B5B;
  text-decoration: none;
}
header{
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #E0E0E0;
  z-index: 9991;
  background: #fdf7ec;
}
header .contenedor{
  justify-content: space-between;
  align-items: center;
}
header nav{
  display: flex;
  min-height: 100px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
header nav ul{
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
header nav ul li{
  padding: 0;
  margin-left: 30px;
  font-weight: 600;
}
header nav ul li:first-child{
  margin: 0;
}
header nav ul li .btn{
  background: #FF8B00;
  color: #fff;
  height: 50px;
  border-radius: 8px;
  display: flex;
  padding: 0 30px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
header nav ul li .btn2{
  background: #FFDAAD;
  color: #FF8B00;
  height: 50px;
  border-radius: 8px;
  display: flex;
  padding: 0 30px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
footer{
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  border-top: 1px solid #E0E0E0;
  z-index: 9991;
  height: 100px;
  background: #fdf7ec;
  color: #5B5B5B;
}
footer .contenedor{
  justify-content: space-between;
}
footer .brands img{
  margin-left: 10px;
}

.component {
  min-height: 100vh;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.component .orbita{
  position: absolute;
  border: 15px solid #fdf7ec;
  width: 50vh;
  height: 50vh;
  border-radius: 100%;
  z-index: 2;
  animation: spin 100s linear infinite;
  background-color: #FFAC47;
}
.component .orbita:after{
  position: absolute;
  content: " ";
  border-radius: 100%;
  top: -16px;
  left: -16px;
  width: calc(100% + 30px);
  height: calc(100% + 30px);
  border: 1px solid #FFAC47;
  z-index: 1;
}
.component .orbita .sat{
  display: flex;
  background: #FFAC47;
  border: 5px solid #fdf7ec;
  width: 10vh;
  height: 10vh;
  border-radius: 100%;
  position: absolute;
  top: calc(-3vh - 3px);
  right: calc(-3vh - 3px);
}
.component .orbita .sat:after{
  position: absolute;
  content: " ";
  border-radius: 100%;
  top: -6px;
  left: -6px;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  border: 1px solid #FFAC47;
  z-index: 1;
}
.component h2{
  display: flex;
  color: #5B5B5B;
  font-size: 48px;
  font-weight: 300;
  max-width: 1200px;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
}
.component .float-txt{
  position: absolute;
  z-index: 9;
  color: #FFAC47;
  
  border: 1px solid #FFAC47;
  height: 40px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  font-size: 16px;
  cursor: default;
  border-radius: 16px;
}
.component .float-txt.ft1{top: 30%; left: 10%;}
.component .float-txt.ft2{top: calc(50% - 20px); left: 5%;}
.component .float-txt.ft3{top: calc(65% - 20px); left: 10%;}
.component .float-txt.ft4{top: calc(80% - 20px); left: 20%;}
.component .float-txt.ft5{top: 30%; right: 10%;}
.component .float-txt.ft6{top: calc(50% - 20px); right: 5%;}
.component .float-txt.ft7{top: calc(65% - 20px); right: 10%;}
.component .float-txt.ft8{top: calc(80% - 20px); right: 20%;}

.component.second-component{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.component.second-component .mid{
  display: flex;
  width: 50%;
  min-height: 100vh;
  position: relative;
  align-items: flex-start;
  position: relative;
  flex-direction: column;
  justify-content: center;
}
.component.second-component .mid .orbita{
  width: 80vh;
  height: 80vh;
  top: 25vh;
  left: -45vh;
  background-color: #FFAC47;
  opacity: 0.6;
}
.component.second-component .mid .orbita .sat{
  background: #FFAC47;
  border: 10px solid #fdf7ec;
  width: 15vh;
  height: 15vh;
  top: calc(-4vh - 3px);
  right: calc(-4vh - 3px);
}
.component.second-component .mid .orbita .sat:after{
  position: absolute;
  content: " ";
  border-radius: 100%;
  top: -11px;
  left: -11px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border: 1px solid #FFAC47;
  z-index: 1;
}
.component.second-component .mid .logoMip{
  position: absolute;
  top: 45%;
  left: -15vh;
  width: 25vh;
  z-index: 3;
}
.component.second-component .mid .logoMip.blur{
  opacity: 0.5;
  filter: blur(44.8757px);
}
.component.second-component .mid h2{
  color: #5B5B5B;
  font-size: 60px;
  font-weight: 500;
  margin: 0;
}
.component.second-component .mid p{
  color: #5B5B5B;
  font-size: 26px;
  line-height: 36px;
  margin: 20px 0;
  font-weight: 300;
}
.component.second-component .mid a{
  font-size: 26px;
  line-height: 30px;
  font-weight: 800;
  display: flex;
  align-items: center;
  color: #FFAC47
}
.component.second-component .mid a span{
  margin-left: 20px;
  margin-bottom: -3px;
  font-size: 30px;
}
.component.second-component{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.component.beneficios-component{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.component.beneficios-component .mid{
  display: flex;
  width: 50%;
  min-height: 100vh;
  position: relative;
  align-items: flex-start;
  position: relative;
  flex-direction: column;
  justify-content: center;
  padding: 0 7%;
  z-index: 91;
}
.component.beneficios-component .mid h2{
  color: #5B5B5B;
  font-size: 60px;
  font-weight: 500;
  margin: 0;
}
.component.beneficios-component .mid p{
  color: #8C8C8C;
  font-size: 26px;
  line-height: 36px;
  margin: 20px 0;
  font-weight: 300;
}
.component.beneficios-component .mid a{
  font-size: 26px;
  line-height: 30px;
  font-weight: 800;
  display: flex;
  align-items: center;
  color: #FFAC47;
}
.component.beneficios-component .mid a span{
  margin-left: 20px;
  margin-bottom: -3px;
  font-size: 30px;
}
.component.beneficios-component .orbita{
  opacity: 0.1 !important;
}
.component.beneficios-component .orbita img{
  opacity: 0.2;
}
.component.beneficios-component .orbita .sat{
  opacity: 1;
}

.component.beneficios-component .float-txt{
  color: #FFAC47;
  border: 1px solid #FFAC47;
  font-size: 20px;
  z-index: 92;
  margin-top: 0;
  transition: 0.6s all ease;
  -webkit-transition: 0.6s all ease;
}
.component.beneficios-component .float-txt span{ 
  position: absolute;
  top: 40px;
  left: 0;
  font-size: 12px;
  padding: 5px;
  width: 100%;
  transition: 0.6s all ease;
  -webkit-transition: 0.6s all ease;
  display: none;
  min-width: 320px;
}
.component.beneficios-component .float-txt:hover{
  color: #5B5B5B;
  margin-top: -20px;
}
.component.beneficios-component .float-txt:hover span{
  color: #fff;
  display: block;
}
.component.beneficios-component .float-txt.ft1{top: calc(30% - 20px); right: 20%; left: auto;}
.component.beneficios-component .float-txt.ft2{top: calc(40% - 20px); right: 15%; left: auto;}
.component.beneficios-component .float-txt.ft3{top: calc(50% - 20px); right: 10%; left: auto;}
.component.beneficios-component .float-txt.ft4{top: calc(60% - 20px); right: 15%; left: auto;}
.component.beneficios-component .float-txt.ft5{top: calc(70% - 20px); right: 20%; left: auto;}


.component.soluciones-component{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.component.soluciones-component .mid{
  display: flex;
  width: 50%;
  min-height: 100vh;
  position: relative;
  align-items: flex-start;
  position: relative;
  flex-direction: column;
  justify-content: center;
  padding: 0 7%;
  z-index: 91;
}
.component.soluciones-component .mid h2{
  color: #5B5B5B;
  font-size: 58px;
  font-weight: 500;
  margin: 0;
}
.component.soluciones-component .mid p{
  color: #8C8C8C;
  font-size: 26px;
  line-height: 36px;
  margin: 20px 0;
  font-weight: 300;
}
.component.soluciones-component .mid a{
  font-size: 26px;
  line-height: 30px;
  font-weight: 800;
  display: flex;
  align-items: center;
  color: #FFAC47;
}
.component.soluciones-component .mid a span{
  margin-left: 20px;
  margin-bottom: -3px;
  font-size: 30px;
}
.component.soluciones-component .orbita{
  opacity: 0.1 !important;
}
.component.soluciones-component .orbita img{
  opacity: 0.2;
}
.component.soluciones-component .orbita .sat{
  opacity: 1;
}
.component.soluciones-component .float-txt{
  color: #FFAC47;
  border: 1px solid #FFAC47;
  font-size: 20px;
  z-index: 92;
}
.component.soluciones-component .float-txt span{ 
  position: absolute;
  top: 40px;
  left: 0;
  font-size: 12px;
  padding: 5px;
  width: 100%;
  transition: 0.6s all ease;
  -webkit-transition: 0.6s all ease;
  display: none;
  min-width: 420px;
}
.component.soluciones-component .float-txt:hover{
  color: #8C8C8C;
  margin-top: -20px;
}
.component.soluciones-component .float-txt:hover span{
  color: #8C8C8C;
  display: block;
}

.component.soluciones-component .float-txt.ft1{top: calc(25% - 20px); left: 20%; right: auto;}
.component.soluciones-component .float-txt.ft2{top: calc(35% - 20px); left: 15%; right: auto;}
.component.soluciones-component .float-txt.ft3{top: calc(45% - 20px); left: 10%; right: auto;}
.component.soluciones-component .float-txt.ft4{top: calc(55% - 20px); left: 15%; right: auto;}
.component.soluciones-component .float-txt.ft5{top: calc(65% - 20px); left: 20%; right: auto;}
.component.soluciones-component .float-txt.ft6{top: calc(75% - 20px); left: 25%; right: auto;}


.component.desarrollo-component{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  box-sizing: border-box;
}
.component.desarrollo-component .mid{
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  position: relative;
  flex-direction: column;
  justify-content: center;
  padding: 0 7%;
  z-index: 91;
  box-sizing: border-box;
}
.component.desarrollo-component .mid h2{
  color: #5B5B5B;
  font-size: 54px;
  font-weight: 500;
  margin: 0;
  width: 100%;
  text-align: center;
  max-width: none;
}
.component.desarrollo-component .mid p{
  color: #8C8C8C;
  font-size: 26px;
  line-height: 36px;
  margin: 20px 0 50px;
  font-weight: 300;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  padding: 0 10vh;
  max-width: 1200px;
}
.component.desarrollo-component .mid a{
  font-size: 26px;
  line-height: 30px;
  font-weight: 800;
  display: flex;
  align-items: center;
  color: #FFAC47;
}
.component.desarrollo-component .mid a span{
  margin-left: 20px;
  margin-bottom: -3px;
  font-size: 30px;
}
.component.desarrollo-component .orbita{
  opacity: 0.2 !important;
}
.component.desarrollo-component .orbita img{
  opacity: 0.2;
}
.component.desarrollo-component .orbita .sat{
  opacity: 1;
}
.component.desarrollo-component .float-txt{
  color: #5B5B5B;
  border: 1px solid #5B5B5B;
  font-size: 18px;
  z-index: 92;
  max-width: 480px;
  height: auto;
  position: relative;
  margin-bottom: 10px;
  padding: 20px;
  line-height: 26px;
  border-radius: 8px;
}
.component.desarrollo-component .float-txt:hover{
  color: #5B5B5B;
}

.component.desarrollo-component .float-txt.ft1{top: calc(25% - 20px); left: auto; right: 0;}
.component.desarrollo-component .float-txt.ft2{top: calc(35% - 20px); left: auto; right: 0;}
.component.desarrollo-component .float-txt.ft3{top: calc(45% - 20px); left: auto; right: 0;}
.component.desarrollo-component .float-txt.ft4{top: calc(55% - 20px); left: auto; right: 0;}
.component.desarrollo-component .float-txt.ft5{top: calc(65% - 20px); left: auto; right: 0;}
.component.desarrollo-component .float-txt.ft6{top: calc(75% - 20px); left: auto; right: 0;}

.react-tabs{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.react-tabs ul.react-tabs__tab-list{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  list-style: none;
  padding: 0;
  margin-bottom: 50px;
  cursor: pointer;
}
.react-tabs ul.react-tabs__tab-list li.react-tabs__tab{
  display: flex;
  margin: 0 20px;
  color: #FFAC47;
  font-weight: 500;
  font-size: 20px;
  height: 40px;
  padding: 0 20px;
  align-content: center;
  align-items: center;
  justify-content: center;
  outline: none;
  font-weight: 500;
}
.react-tabs ul.react-tabs__tab-list li.react-tabs__tab.react-tabs__tab--selected{
  border: 2px solid #FFAC47;
  opacity: 1;
  border-radius: 8px;
}

.react-tabs__tab-panel{
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.component.contacto-component{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.component.contacto-component .mid{
  display: flex;
  width: 50%;
  min-height: 100vh;
  position: relative;
  align-items: flex-start;
  position: relative;
  flex-direction: column;
  justify-content: center;
  padding: 0 7%;
  z-index: 91;
}
.component.contacto-component .mid h2{
  color: #5B5B5B;
  font-size: 58px;
  font-weight: 500;
  margin: 0;
}
.component.contacto-component .mid p{
  color: #8C8C8C;
  font-size: 26px;
  line-height: 36px;
  margin: 20px 0;
  font-weight: 300;
}
.component.contacto-component .mid a{
  font-size: 26px;
  line-height: 30px;
  font-weight: 800;
  display: flex;
  align-items: center;
  color: #FFAC47;
}
.component.contacto-component .mid a span{
  margin-left: 20px;
  margin-bottom: -3px;
  font-size: 30px;
}
.component.contacto-component .orbita{
  top: 60%;
}
.component.contacto-component .orbita{
  opacity: 0.1 !important;
}
.component.contacto-component .orbita img{
  opacity: 0.2;
}
.component.contacto-component .orbita .sat{
  opacity: 1;
}
.component.contacto-component .mid form{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.component.contacto-component .mid form .campo{
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
  justify-content: flex-end;
}
.component.contacto-component .mid form .campo .campo-m{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(50% - 10px);
  margin-right: 20px;
}
.component.contacto-component .mid form .campo .campo-m:last-child{
  margin-right: 0;
}
.component.contacto-component .mid form .campo input{
  display: flex;
  width: 100%;
  border: 1px solid #5B5B5B;
  border-radius: 8px;
  height: 50px;
  font-size: 14px;
  padding: 0 20px;
  background: transparent;
  outline: none;
  color: #5B5B5B;
}
.component.contacto-component .mid form .campo input::placeholder {
  color: #5B5B5B;
}
.component.contacto-component .mid form .campo textarea{
  display: flex;
  width: 100%;
  border: 1px solid #5B5B5B;
  border-radius: 8px;
  height: 80px;
  font-size: 14px;
  padding: 20px;
  background: transparent;
  outline: none;
  color: #5B5B5B;
}
.component.contacto-component .mid form .campo textarea::placeholder {
  color: #5B5B5B;
}
.component.contacto-component .mid form .campo button{
  display: flex;
  border: 1px solid #FFAC47;
  background-color: #FFAC47;
  color: #fff;
  height: 50px;
  font-weight: 600;
  font-size: 16px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 16px;
  padding: 0 20px 0 20px;
}
.component.contacto-component .mid form .campo button span{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.component.cau-component{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.component.cau-component .mid{
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  position: relative;
  flex-direction: column;
  justify-content: center;
  padding: 0 7%;
  z-index: 91;
}
.component.cau-component .mid h2{
  color: #5B5B5B;
  font-size: 52px;
  font-weight: 600;
  margin: 0;
  text-align: center;
  width: 100%;
  margin-top: 150px;
}
.component.cau-component .mid p{
  color: #8C8C8C;
  font-size: 26px;
  line-height: 36px;
  margin: 20px 0;
  font-weight: 300;
}
.component.cau-component .mid a{
  font-size: 26px;
  line-height: 30px;
  font-weight: 800;
  display: flex;
  align-items: center;
  color: #FFAC47;
}
.component.cau-component .mid a span{
  margin-left: 20px;
  margin-bottom: -3px;
  font-size: 30px;
}
.component.cau-component .orbita{
  top: 60%;
}
.component.cau-component .orbita{
  opacity: 0.1 !important;
}
.component.cau-component .orbita img{
  opacity: 0.2;
}
.component.cau-component .orbita .sat{
  opacity: 1;
}
.component.cau-component .mid form{
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 640px;
  margin-bottom: 100px;
}
.component.cau-component .mid form .campo{
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
  justify-content: flex-end;
}
.component.cau-component .mid form .campo .campo-m{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(50% - 10px);
  margin-right: 20px;
}
.component.cau-component .mid form .campo .campo-m:last-child{
  margin-right: 0;
}
.component.cau-component .mid form .campo input{
  display: flex;
  width: 100%;
  border: 1px solid #5B5B5B;
  border-radius: 8px;
  height: 50px;
  font-size: 14px;
  padding: 0 20px;
  background: transparent;
  outline: none;
  color: #5B5B5B;
}
.component.cau-component .mid form .campo input::placeholder {
  color: #5B5B5B;
}
.component.cau-component .mid form .campo textarea{
  display: flex;
  width: 100%;
  border: 1px solid #5B5B5B;
  border-radius: 8px;
  height: 80px;
  font-size: 14px;
  padding: 20px;
  background: transparent;
  outline: none;
  color: #5B5B5B;
}
.component.cau-component .mid form .campo textarea::placeholder {
  color: #5B5B5B;
}
.component.cau-component .mid form .campo button{
  display: flex;
  border: 1px solid #FFAC47;
  background-color: #FFAC47;
  color: #fff;
  height: 50px;
  font-weight: 600;
  font-size: 16px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 16px;
  padding: 0 20px 0 20px;
}
.component.cau-component .mid form .campo button span{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.first-component--inside-scroller {
  position: absolute;
  right: 20px;
  top: 100px;
}
.demo-page-contain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}
.demo-page-contain__hint {
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .pagination-additional-class > li:not(:last-of-type) > a, .pagination-additional-class > li:not(:last-of-type) > span {
      margin-right: 5px;
  }
}
@keyframes spin {
  100% { 
    -webkit-transform: rotate(360deg); 
    transform:rotate(360deg); 
  } 
}
@media only screen and ( min-width:320px ) and (max-width:719px){
	.desktop, .tablet{ display: none !important; }
	.movil{ display: flex !important; }

  body{
    padding-bottom: 100px;
  }
  .component .orbita{
    width: 70vw;
    height: 70vw;
  }
  .component .orbita .sat{
    border: 5px solid #27B1D9;
    width: 7vw;
    height: 7vw;
    top: 6vw;
    right: 5vw;
  }
  .component h2{
    font-size: 20px;
  }
  .component .float-txt{
    padding: 0 10px;
    font-size: 12px;
    height: 30px;
  }
  header nav ul li{
    margin-left: 0;
  }
  header nav ul li a{
    font-size: 14px;
    text-align: center;
  }
  header nav ul li:first-child{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  header nav ul li a.btn{
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
  }
  .component .float-txt.ft1{top: 17vh; left: 5vh;}
  .component .float-txt.ft2{top: calc(30vh - 20px); left: 2vh;}
  .component .float-txt.ft3{top: calc(72vh - 20px); left: 2vh;}
  .component .float-txt.ft4{top: calc(82vh - 20px); left: 5vh;}
  .component .float-txt.ft5{top: 17vh; right: 5vh;}
  .component .float-txt.ft6{top: calc(30vh - 20px); right: 2vh;}
  .component .float-txt.ft7{top: calc(72vh - 20px); right: 2vh;}
  .component .float-txt.ft8{top: calc(82vh - 20px); right: 5vh;}

  .component .contenedor{
    flex-direction: column;
    padding: 0 4vw;
  }
  .component.second-component .mid{
    min-height: 100vw;
    width: 100%;
    margin-bottom: -120px;
  }
  .component.second-component .mid .orbita{
    width: 80vw;
    height: 80vw;
    top: 5vh;
    left: -15vw;
  }
  .component.second-component .mid .orbita .sat{
    top: 10vw;
  }
  .component.second-component .mid .logoMip{
    position: absolute;
    top: 20vh;
    left: 5vh;
    width: 20vh;
  }
  .component.second-component .mid h2{
    font-size: 24px;
  }
  .component.second-component .mid p{
    font-size: 14px;
    line-height: 20px;
  }
  .component.second-component .mid a{
    font-size: 16px; 
  }

  .component.beneficios-component{
    flex-direction: column;
  }
  .component.beneficios-component .mid{
    min-height: 100vw;
    width: 100%;
    margin-bottom: -100px;
    padding: 0 4vw;
    box-sizing: border-box;
  }
  .component.beneficios-component .mid .orbita{
    width: 80vw;
    height: 80vw;
    top: 5vh;
    left: -15vw;
  }
  .component.beneficios-component .mid .orbita .sat{
    top: 10vw;
  }
  .component.beneficios-component .mid h2{
    font-size: 24px;
  }
  .component.beneficios-component .mid p{
    font-size: 14px;
    line-height: 20px;
  }
  .component.beneficios-component .mid:nth-child(3){
    display: none;
  }
  .component.beneficios-component .float-txt{
    position: relative;
    top: 0 !important;
    left: 0 !important;
    right: auto !important;
    font-size: 14px;
    margin-bottom: 20px;
    overflow: hidden;
  }
  .component.beneficios-component .float-txt:hover{
    margin-top: 0;
  }
  .component.beneficios-component .float-txt:hover span{
    display: none;
  }

  .component.soluciones-component{
    flex-direction: column;
  }
  .component.soluciones-component .mid{
    min-height: 100vw;
    width: 100%;
    margin-top: -100px;
    margin-bottom: -100px;
    padding: 0 4vw;
    box-sizing: border-box;
  }
  .component.soluciones-component .mid .orbita{
    width: 80vw;
    height: 80vw;
    top: 5vh;
    left: -15vw;
  }
  .component.soluciones-component .mid .orbita .sat{
    top: 10vw;
  }
  .component.soluciones-component .mid h2{
    font-size: 26px;
  }
  .component.soluciones-component .mid p{
    font-size: 14px;
    line-height: 20px;
  }
  .component.soluciones-component .mid:nth-child(2){
    display: none;
  }
  .component.soluciones-component .float-txt{
    position: relative;
    top: 0 !important;
    left: 0 !important;
    right: auto !important;
    font-size: 14px;
    margin-bottom: 20px;
    overflow: hidden;
  }
  .component.soluciones-component .float-txt:hover{
    margin-top: 0;
  }
  .component.soluciones-component .float-txt:hover span{
    display: none;
  }


  .component.desarrollo-component{
    flex-direction: column;
  }
  .component.desarrollo-component .mid{
    min-height: 100vw;
    width: 100%;
    margin-bottom: -100px;
    padding: 0 4vw;
    box-sizing: border-box;
  }
  .component.desarrollo-component .mid .orbita{
    width: 80vw;
    height: 80vw;
    top: 5vh;
    left: -15vw;
  }
  .component.desarrollo-component .mid .orbita .sat{
    top: 10vw;
  }
  .component.desarrollo-component .mid h2{
    font-size: 24px;
  }
  .component.desarrollo-component .mid p{
    font-size: 14px;
    line-height: 20px;
  }
  .component.desarrollo-component .mid:nth-child(3){
    margin-bottom: 0;
  }
  .component.desarrollo-component .float-txt{
    position: relative;
    top: 0 !important;
    left: 0 !important;
    right: auto !important;
    font-size: 14px;
    margin-bottom: 20px;
    overflow: hidden;
  }
  .component.desarrollo-component .float-txt:hover{
    margin-top: 0;
  }
  .component.beneficios-component .float-txt:hover span{
    display: none;
  }

  .component.contacto-component{
    flex-direction: column;
  }
  .component.contacto-component .mid{
    min-height: 100vw;
    width: 100%;
    margin-bottom: -100px;
    padding: 0 4vw;
    box-sizing: border-box;
  }
  .component.contacto-component .mid h2{
    font-size: 24px;
  }
  .component.contacto-component .mid p{
    font-size: 14px;
    line-height: 20px;
  }
  .component.contacto-component .mid:nth-child(3){
    margin-bottom: 50px;
  }
  .component.contacto-component .mid form .campo input{
    font-size: 12px;
  }
  .component.contacto-component .mid form .campo textarea{
    font-size: 12px;
  }
  .react-tabs ul.react-tabs__tab-list li.react-tabs__tab{
    font-size: 14px;
    margin: 0 20px 20px;
  }

  .react-tabs ul.react-tabs__tab-list{
    flex-wrap: wrap;
  }
  footer .contenedor{
    flex-wrap: wrap;
    padding: 10px 7%;
  }
  footer .contenedor .brands{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    align-content: center; 
  }
}